.logo{
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 10px;
  line-height: 155%;
  text-transform: uppercase;
  img{
    width: 130px;
    display: block;
    margin-right: 8px;
  }
}


.header{
  &__logo{
    margin-right: auto;
  }
  &__select{
    padding: 0 10px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%,-50%);
      background-color: rgba(#fff,.1);
      height: 17px;
      width: 1px;
    }
  }
  &__country{
    margin-right: 10px;
    .option,
    .custom-select{
      background-repeat: no-repeat !important;
      background-size: 20px auto !important;
      background-position: left center !important;
      padding-left: 28px;
    }
    .option{
      padding-left: 28px;
    }
  }
  &__select,
  &__country{
    .list{
      background-color: #EBF4FF !important;
    }
  }
  &__favourite,
  &__cart{
    position: relative;
    &--active{
      &:after{
        content:attr(data-num);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        min-width: 24px;
        padding: 0 5px;
        border-radius: 10px;
        background-color: $darkGray;
        font-weight: 600;
        font-size: 10px;
        color: #fff;
      }
    }
  }
  &__favourite{
    margin-right: 26px;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    position: relative;
    background-image: url(../img/favourite.svg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    &--active{
      background-image: url(../img/favourite2.svg);
      &:after{
        bottom: 2px;
        right: -12px;
      }
    }
  }
  &__cart{
    height: 48px;
    width: 145px;
    background: rgba(0, 117, 255, 0.1);
    border-radius: 8px;
    font-size: 14px;
    &:hover{
      background: rgba(0, 117, 255, 0.4);
      color: $black;
    }
    span{
      margin-left: 16px;
    }
    &-wrap{
      position: relative;
    }
    &--active{
      &:after{
        margin-left: 4px;
        top: 0;
        right: 0;
        transform: translate(50%,-50%);
      }
    }
  }
  &__contacts{
    margin: 0 39px;
    display: flex;
    align-items: center;
  }
  &__phone{
    display: flex;
    align-items: center;
    font-size: 18px;
    white-space: nowrap;
    img{
      flex-shrink: 0;
      margin-right: 4px;
    }
    &-link{
      display: flex;
      align-items: center;
    }
    &-btn{
      font-size: 14px;
      line-height: 17px;
      margin-left: 8px;
      color: $primary;
    }
  }
  &__whatsapp{
    margin-left: 46px;
  }
  &__email{
    margin-left: 46px;
    display: flex;
    align-items: center;
    font-size: 14px;
    img{
      flex-shrink: 0;
      margin-right: 2px;
    }
  }
  &__catalog-btn{
    padding: 0 24.5px;
    height: 48px;
    font-weight: 600;
    font-size: 18px;
    flex-shrink: 0;
    &--active .header__catalog-btn-burger{
      span{
        &:nth-child(1){
          transform: rotate(-45deg) translate(-3.5px,5px);
        }
        &:nth-child(2){
          transform: scale(0);
        }
        &:nth-child(3){
          transform: rotate(45deg) translate(-3.5px,-5px);
        }
      }
    }
    &-burger{
      flex-shrink: 0;
      margin-right: 18px;
      width: 20px;
      height: 14px;
      display: flex;
      align-items: center;
      position: relative;
      span{
        display: block;
        width: 100%;
        height: 2px;
        background: #fff;
        border-radius: 3px;
        left: 0;
        transition: all .3s;
        &:nth-child(1){
          position: absolute;
          top: 0;
        }
        &:nth-child(3){
          position: absolute;
          bottom: 0;
        }
      }

    }

  }
  &__search{
    margin-left: 24px;
    flex-grow: 1;
    input{
      font-size: 14px;
      border-radius: 8px;
      height: 48px;
      padding-left: 64px;
      background-image: url(../img/search.svg);
      background-repeat: no-repeat;
      background-position: 24px center;
      border-width: .5px;
    }
  }
  &--fix{
    @media screen and (min-width: 992px) {
      .header-top{
        opacity: 0;
      }

    }
    .header-middle,
    .header-bottom {
      opacity: 0;
    }
    .header-fix {
      transform: translateY(0%);
      opacity: 1;
      pointer-events: unset;
      .header__search{
        opacity: 1;
        pointer-events: unset;
      }
    }
    .header-catalog{
      top: 98px;
    }
    .cart-info{
      display: block !important;
    }
  }
  &._open-search{
    .header-search{
      z-index: 32;
      input{
        border-color: transparent;
        padding: 0 275px 0 24px;
        background-image: none;
        position: relative;
        z-index: 33;
        color: $black;
        &::placeholder{
          color: transparent;
        }
      }
      &__bg{
        display: block;
      }
      &__dropdown{
        opacity: 1;
        pointer-events: unset;
      }
    }
    .header-fix{
      //z-index: unset;
      .header__search{
        z-index: 40;
      }
    }
    &.header--fix{
      .header-search{
        &__dropdown{
          border: 1px solid $primary;
        }
        &__bg{
          //font: 22;
        }
      }
    }
  }
}

.header-top{
  background-color: $darkGray;
  padding: 10px 0;
  color: #fff;
  &__inner{
    width: 1440px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__left{
    display: flex;
    align-items: center;
  }
}

.nav{
  display: flex;
  align-items: center;
  &__link{
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 17px;
    margin-left: 24px;
    &:hover{
      color: $gray;
    }
    img{
      margin-right: 8px;
      flex-shrink: 0;
    }
  }
}

.header-middle{
  padding: 25px 0;
  &__inner{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .header__cart--active{
    &::after{
      position: static;
      background-color: transparent;
      color: $black;
      font-size: inherit;
      line-height: inherit;
      transform: unset;
      min-width: unset;
      height: unset;
      padding: 0;
      margin: 0 3px 0 8px;
    }
    span{
      order: 2;
      margin-left: 0;
    }
  }
}

.header-bottom{
  padding-bottom: 32px;
  &__inner{
    display: flex;
    align-items: center;
  }
}

.header-catalog{
  position: fixed;
  width: 100%;
  top: 226px;
  left: 0;
  height: 650px;
  max-height: calc(100vh - 276px);
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
  z-index: 30;
  overflow-y: auto;
  padding: 25px 0 62px;
  opacity: 0;
  pointer-events: none;
  transition: all .3s;
  &--active{
    pointer-events: unset;
    opacity: 1;
    &~.header-catalog__bg{
      display: block;
    }
  }
  &__bg{
    z-index: 29;
    position: fixed;
    height: calc(100vh - 226px);
    width: 100%;
    left: 0;
    top: 226px;
    background: #314578;
    opacity: 0.9;
    box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
    display: none;
  }
  &__inner{
    position: relative;
  }
  &__group{
    &:not(:last-child){
      margin-bottom: 16px;
    }
  }
  &__tab{
    border-bottom: 0.5px solid #CBD3DF;
    padding: 10px 24px;
    width: 196px;
    min-height: 48px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .3s;
    &--active,
    &:hover{
      font-weight: 600;
      background: #F9F9F9;
      border-color: #F9F9F9;
      border-radius: 8px;
    }
    &--active{
      pointer-events: none;
    }
    svg{
      display: none;
    }
  }
  &__content{
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    padding-bottom: 62px;
    width: calc(100% - 295px);
  }
  &__grid{
    display: grid;
    grid-template-columns: repeat(3, calc(33.333% - (32px / 3)));
    gap: 16px;
  }
}

.header-fix{
  transition: all .4s;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
  z-index: 28;
  pointer-events: none;
  .cart-info{
    display: none;
  }
  .header{
    &__search{
      opacity: 0;
      pointer-events: none;
    }
    &__contacts{
      margin: 0 39px 0 24px;
    }
    &__phone{
      font-size: 14px;
      img{
        margin-right: 2px;
      }
    }
    &__whatsapp{
      margin-left: 24px;
    }
    &__email{
      margin-left: 40px;
    }
    &__cart{
      width: 74px;
      &--active{
        &:after{
          background: transparent;
          min-width: unset;
          height: unset;
          position: static;
          font-size: 14px;
          line-height: 17px;
          color: $black;
          transform: unset;
          padding: 0;
        }
      }
    }
  }
}


@media screen and (max-width: 1180px){
  .header{
    &__contacts{
      margin: 0 20px;
    }
    &__whatsapp,
    &__email{
      margin-left: 25px;
    }
  }

  .header-fix{
    .header{
      &__contacts{
        margin: 0 10px;
      }
      &__whatsapp{
        margin-left: 10px;
      }
      &__email{
        margin-left: 10px;
      }
      &__favourite{
        margin-right: 20px;
      }
      &__cart{
        width: 60px;
      }
      &__search{
        margin-left: 10px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .header{
    &__logo{
      span{
        display: none;
      }
    }
  }

  .header-catalog{
    &__grid{
      grid-template-columns: repeat(2, calc(50% - 8px));
    }
  }
}


@media screen and (max-width: 992px) {
  .header{
    &__country{
      .custom-select{
        .current{
          padding-right: 12px;
          font-size: 0;
        }
      }
    }
    &__contacts{
      width: 100%;
      order: 3;
      margin: 24px 0 0;
      justify-content: flex-end;
    }
    &__phone{
      margin-right: auto;
      &-btn{
        display: none;
      }
    }
    &__email{
      margin-left: 46px;
      img{
        margin: 0;
      }
      span{
        display: none;
      }
    }
    &__favourite{
      margin-right: 15px;
    }
    &__search{
      margin-left: 8px;
      width: calc(100% - 79px);
      input{
        background-position: 8px center;
        padding-left: 36px;
      }
    }
    &__catalog-btn{
      padding: 0 7px;
      font-size: 14px;
      width: 71px;
      &-title{
        span{
          display: none;
        }
      }
      &-burger{
        display: none;
        margin-right: 0;
      }
      &--active{
        .header__catalog-btn-burger{
          display: flex;
        }
        .header__catalog-btn-title{
          display: none;
        }
      }
    }
    &--fix{
      position: sticky;
      z-index: 20;
      top: 0;
      .header-top{
        position: relative;
        z-index: 55;

      }
      .header-fix{
        pointer-events: unset;
        opacity: 1;
        transition: .3s;
      }
      .header-catalog{
        top: 128px;
        height: calc(100vh - 128px);
      }
    }
    &._open-search{
      .header-search{
        input{
          width: calc(100% + 79px);
          margin-left: -79px;
          padding-right: 24px;
        }
      }
    }
  }

  .nav{
    &__link{
      display: none;
      &--support{
        display: flex;
        img{
          margin: 0;
        }
        span{
          display: none;
        }
      }
    }
  }

  .header-middle{
    padding-top: 16px;
    &__inner{
      flex-wrap: wrap;
    }
  }

  .header-bottom{
    padding-bottom: 16px;
  }

  .header-fix{
    //display: none;
    transform: unset;
    top: 48px;
    opacity: 0;
    pointer-events: none;
    transition: .1s;
    .header__cart,
    .header__favourite,
    .header__contacts{
      display: none;
    }
    .header-middle__inner{
      flex-wrap: nowrap;
    }
  }

  .header-catalog{
    top: 250px;
    height: calc(100vh - 250px);
    max-height: unset;
    padding-top: 0;
    &__bg{
      display: none !important;
    }
    &__group{
      background: #FFFFFF;
      box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
      border-radius: 8px;
      padding: 16px;
    }
    &__tab{
      width: 100%;
      padding: 0;
      border-radius: 0;
      border:none;
      min-height: unset;
      font-weight: 500;
      &:hover{
        font-weight: 500;
        background-color: transparent;
      }
      svg{
        display: unset;
        margin-left: 8px;
      }
      &--active{
        pointer-events: unset;
        background-color: transparent;
        svg{
          transform: rotate(180deg);
        }
      }
    }
    &__content{
      position: static;
      width: 100%;
      padding: 8px 0 0;
      //display: none !important;
    }
    &__grid{
      grid-template-columns: 100%;
      gap: 8px;
    }
    &__item{

    }
  }
}


@media screen and (max-width: 390px){
  .header{
    &__cart{
      width: 120px;
      span{
        margin-left: 7px;
      }
    }
  }
}


@media screen and (max-width: 360px){
  .header{
    &__logo{
      img{
        width: 110px;
      }
    }
    &__cart{
      width: 110px;
    }
    &__phone{
      font-size: 16px;
    }
    &__email{
      margin-left: 25px;
    }
  }

  .header-middle{
    .header__cart--active{
      &::after{
        margin: 0 3px;
      }
    }
  }
}
