.catalog{
  &__wrap{
    display: grid;
    grid-template-columns: 270px calc(100% - 296px);
    gap: 26px;
    margin-top: 30px;
  }
  &__filter-btn{
    display: none;
  }
  &__content{
    padding-top: 41px;
  }
  &__sort{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__select{
    display: flex;
    align-items: center;
    &-title{
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      margin-right: 8px;
      white-space: nowrap;
    }
    .custom-select{
      background: #FFFFFF;
      box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
      border-radius: 8px;
      color: $primary;
      font-size: 14px;
      height: 33px;
      font-weight: 600;
      .current{
        padding: 0 36px 0 16px;
        background-image: url(../img/select-arrow3.svg);
        background-position: right 16px center;
        background-size: 12px auto;
      }
      &.open{
        .list{
          background-image: url(../img/select-arrow4.svg);
          background-position: right 10px top 13.5px;
        }
      }
    }
  }
  &__grid{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 16px));
    gap: 24px;
  }
}

.filter{
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
  border-radius: 8px;
  padding: 32px 24px 40px;
  &__top,
  &__title,
  &__btn{
    display: none;
  }
  &__group{
    &:not(:last-child){
      margin-bottom: 24px;
    }
  }
  &__subtitle{
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 16px;
  }
  &__check{
    &:not(:last-child){
      margin-bottom: 8px;
    }
  }
  &__range{
    display: flex;
    align-items: center;
    max-width: 190px;
    &-title{
      margin: 0 8px;
      &:first-child{
        margin-left: 0;
      }
    }
    input{
      width: 64px;
      height: 32px;
      flex-grow: 1;
      text-align: right;
      padding: 0 12px;
      font-size: 16px;
    }
  }
  &__selected{
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 8px);
    margin-bottom: 16px;
  }
  &__item{
    margin: 0 8px 8px 0;
    padding: 0 16px;
    height: 31px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    &-remove{
      margin-left: 8px;
    }
    &--all{
      background-color: $grayBg;
      color: $darkGray;
      flex-grow: 1;
      width: 100%;
    }
  }
}

.catalog-quiz{
  grid-column: 1/4;
  box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
  border-radius: 8px;
  background: #2A3640;
  position: relative;
  padding: 16px 24px 16px 154px;
  display: flex;
  align-items: center;
  color: #fff;
  overflow: hidden;
  &--article{
    display: block;
    padding: 24px 24px 37px 24px;
    .catalog-quiz__content{
      max-width: 225px;
    }
    .catalog-quiz__img{
      left: unset;
      right: 0;
    }
    .catalog-quiz__desc{
      margin-top: 10px;
    }
    .catalog-quiz__btn{
      margin: 16px 0 0;
      width: 143px;
      svg{
        margin-left: 13px;
      }
    }
  }
  &__img{
    position: absolute;
    right: calc(100% - 134px);
    top: 0;
    height: 100%;
    width: auto;
  }
  &__btn{
    margin-left: 16px;
    flex-shrink: 0;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    width: 109px;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    &:hover{
      background-color: rgba($darkGray,.6);
      color: #fff;
    }
  }
  &__content{
    position: relative;
    z-index: 2;
  }
  &__desc{
    margin-top: 2px;
  }
}


@media screen and (max-width: 1100px){
  .catalog{
    &__grid{
      grid-template-columns: repeat(2, calc(50% - 12px));
    }
  }

  .catalog-quiz{
    grid-column: 1/3;
  }
}


@media screen and (max-width: 992px){
  .catalog{
    &__wrap{
      grid-template-columns: 100%;
      margin-top: 16px;
      gap: 0;
    }
    &__filter-btn{
      display: flex;
      height: 48px;
      width: 100%;
      border-radius: 4px;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 0.01em;
      margin-top: 8px;
      svg{
        margin-right: 10px;
      }
    }
    &__content{
      padding: 0;
    }
    &__select{
      &-title{
        display: none;
      }
    }
    &__grid{
      grid-template-columns: repeat(2, calc(50% - 8px));
      gap: 16px;
    }
  }

  .filter{
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 0;
    z-index: 31;
    height: 100vh;
    padding: 0;
    transform: translateX(-100%);
    transition: all .3s;
    width: 360px;
    max-width: 100%;
    &--active{
      transform: translateX(0);
      &~.filter__bg{
        display: block;
      }
    }
    &__scroll{
      padding: 32px 40px 70px;
      overflow-y: auto;
      height: calc(100% - 72px);
    }
    &__bg{
      display: none;
      z-index: 30;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(49, 69, 120, 0.5);
      box-shadow: 0px 12px 32px rgba(0, 51, 153, 0.12), 0px 8px 20px rgba(0, 51, 153, 0.08);
    }
    &__top{
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 24px;
    }
    &__title{
      margin-bottom: 24px;
      display: block;
    }
    &__btn{
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 48px;
      width: 100%;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.01em;
      border-radius: 0;
    }
  }

  .catalog-quiz{
    display: none;
  }
}


@media screen and (max-width: 650px){
  .catalog{
    &__grid{
      grid-template-columns: 100%;
    }
  }
}
