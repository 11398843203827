.category{
  &__group{
    margin-top: 48px;
  }
  &__subtitle{
    img,
    svg{
      display: none;
    }
  }
  &__grid{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 16px));
    gap: 24px;
  }
}


@media screen and (max-width: 992px){
  .category{
    &__group{
      background: #FFFFFF;
      box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
      border-radius: 8px;
      margin-top: 16px;
      overflow: hidden;
    }
    &__subtitle{
      position: relative;
      font-size: 16px;
      line-height: 150%;
      padding: 16px;
      min-height: 56px;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      svg{
        display: unset;
        margin-left: 8px;
      }
      img{
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        pointer-events: none;
        transition: all .2s;
      }
      &:hover{
        color: $primary;
      }
      &--active{
        svg{
          transform: rotate(180deg);
        }
        img{
          opacity: 0;
        }
      }
    }
    &__grid{
      margin: 0;
      display: none;
      padding: 0 16px 16px;
    }

  }
}
