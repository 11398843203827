.contacts{
  &__inner{
    margin-top: 32px;
    display: grid;
    grid-template-columns: calc(100% - 352px) 328px;
    gap: 24px;
  }
  &__grid{
    display: grid;
    grid-template-columns: 100%;
    gap: 24px;
  }
  &__group{
    &-title{
      margin-bottom: 16px;
    }
  }
  &__phone{
    display: block;
    font-size: 20px;
    line-height: 150%;
  }
  &__text{
    max-width: 280px;
  }
  &__tabs{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 16px;
  }
  &__tab{
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
    border-radius: 8px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    height: 33px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.01em;
    color: $primary;
    margin: 8px 8px 0 0;
    cursor: pointer;
    transition: all .3s;
    &--active,
    &:hover{
      box-shadow: inset 0px 4px 16px rgba(0, 51, 153, 0.04), inset 0px 2px 2px rgba(0, 51, 153, 0.08);
    }
    &--active{
      pointer-events: none;
      color: $gray;
    }
  }
}

.map{
  overflow: hidden;
  border-radius: 8px;
  height: 565px;
}

.map-card{
  width: 270px;
  padding: 6px;
  &__title{
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
  }
  &__desc,
  &__desc2,
  &__subtitle,{
    font-size: 12px;
    line-height: 150%;
  }
  &__desc,
  &__item{
    margin-top: 8px;
  }
  &__subtitle{
    font-weight: 700;
  }
}

.ymaps-2-1-79-balloon{
  width: 320px;
  z-index: 2;
}


@media screen and (max-width: 992px){
  .contacts{
    &__inner{
      margin-top: 16px;
      grid-template-columns: 100%;
    }
    &__right{
      order: -1;
    }
  }

  .map{
    padding: 0;
    height: 560px;
  }
}
