.catalog-card{
  position: relative;
  min-height: 104px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
  border-radius: 8px;
  padding: 16px 30px 16px 16px;
  &:hover{
    box-shadow: 0px 8px 24px rgba(0, 51, 153, 0.06), 0px 8px 16px rgba(0, 51, 153, 0.12);
    .catalog-card__img{
      opacity: 1;
    }
    .catalog-card__num{
      background-color: $primary;
    }
  }
  &__img{
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    right: 0;
    opacity: 0;
    transition: all .3s;
  }
  &__text{
    max-width: 185px;
  }
  &__title{
    font-weight: 500;
  }
  &__num{
    display: inline-flex;
    padding: 4px 8px;
    background-color: $gray;
    border-radius: 12px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
  }
}

.small-card,
.product-card{
  background: #FFFFFF;
  box-shadow: 0 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
  border-radius: 8px;
  transition: all .3s;
  &:hover{
    box-shadow: 0px 8px 24px rgba(0, 51, 153, 0.06), 0px 8px 16px rgba(0, 51, 153, 0.12);
  }
  &__img{
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
    display: block;
    img{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      position: absolute;
      max-width: 100%;
      height: 100%;
      width: auto;
    }
  }
  &__title{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient: vertical;
  }
}

.product-card{
  padding: 32px 34px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  &__favourite{
    position: absolute;
    top: 16px;
    right: 16px;
  }
  &__img{
    margin: 0 auto;
    width: 160px;
    max-height: 160px;
    padding-bottom: 78%;
  }
  &__title{
    margin-top: 24px;
  }
  &__bottom{
    margin-top: auto;
    padding-top: 10px;
  }
  &__price{
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    &-title{
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: $primary;
    }
  }
  &__btn{
    width: 100%;
    height: 48px;
    margin-top: 40px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
    svg{
      margin-right: 10px;
    }
  }
  &__btn2{
    margin: 12px auto 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: $darkGray;
    &:hover{
      color: $primary;
    }
  }
}

.service-card{
  display: block;
  padding: 32px 40px 40px 24px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
  border-radius: 8px;
  position: relative;
  &__img{
    position: absolute;
    height: 100%;
    width: auto;
    top: 0;
    right: 0;
  }
  &__content{
    position: relative;
    z-index: 2;
  }
  &__title{
    max-width: 295px;
  }
  &__desc{
    margin-top: 10px;
    max-width: 240px;
  }
  &__btn{
    margin-top: 34px;
    width: 166px;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
  }
  &__link{
    display: block;
    margin-top: 24.5px;
    line-height: 19px;
    letter-spacing: 0.01em;
  }
}

.info-card{
  background: #F9F9F9;
  box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
  border-radius: 8px;
  position: relative;
  padding: 32px 24px 60px;
  overflow: hidden;
  &__img{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    pointer-events: none;
  }
  &__content{
    position: relative;
    z-index: 2;
    max-width: calc(100% - 90px);
  }
  &__desc{
    margin-top: 10px;
  }
}

.equip-card{
  display: block;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
  border-radius: 8px;
  padding: 32px 24px 40px;
  &--small{
    padding-bottom: 86px;
  }
  &__img{
    position: absolute;
    max-height: 100%;
    width: auto;
    bottom: 0;
    right: 0;
  }
  &__content{
    max-width: 270px;
  }
  &__title{
    font-weight: 500;
  }
  &__num{
    display: inline-flex;
    padding: 5px 8px;
    background-color: $primary;
    border-radius: 12px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }
  &__desc{
    margin-top: 10px;
    color: $darkGray;
    max-width: 241px;
    p,
    ul{
      &:not(:last-child){
        margin-bottom: 5px;
      }
    }
    ul li{
      padding-left: 7px;
      &:not(:last-child){
        margin-bottom:0;
      }
      &::before{
        width: 4px;
        height: 4px;
        margin-right: 9px;
      }
    }
    a{
      line-height: inherit;
      @media screen and (min-width: 992px) {
        color: inherit !important;
        &:hover{
          color: $primary !important;
        }
      }
    }
  }
  &__bottom{
    display: block;
    margin-top: 24px;
    span{
      color: $primary;
    }
  }
}

.small-card{
  position: relative;
  display: flex;
  padding: 8px;
  &__content{
    display: flex;
    flex-direction: column;
  }
  &__img{
    width: 80px;
    height: 80px;
    margin-right: 8px;
  }
  &__title{
    font-size: 12px;
    line-height: 150%;
  }
  &__price{
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    margin-top: auto;
    padding-top: 7px;
    &-title{
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $primary;
    }
  }
}

.cart-card{
  padding: 16px 24px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  position: relative;
  transition: all .3s;
  @media screen and (min-width: 768px) {
    &:hover {
      background: #FFFFFF;
      box-shadow: 0px 8px 24px rgba(0, 51, 153, 0.06), 0px 8px 16px rgba(0, 51, 153, 0.12);
      border-color: #fff !important;
      z-index: 2;
      transform: scale(1.006);

      .cart-card__favourite:not(.favourite-btn--active) {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7878 3.10251C11.283 2.09913 12.7138 2.09913 13.209 3.10251L15.567 7.88023L20.8395 8.64638C21.9468 8.80728 22.3889 10.168 21.5877 10.9491L17.7724 14.668L18.6731 19.9192C18.8622 21.022 17.7047 21.863 16.7143 21.3424L11.9984 18.8631L7.28252 21.3424C6.29213 21.863 5.13459 21.0221 5.32374 19.9192L6.2244 14.668L2.40916 10.9491C1.60791 10.168 2.05005 8.80728 3.15735 8.64638L8.42988 7.88023L10.7878 3.10251ZM11.9984 4.03891L9.74008 8.6148C9.54344 9.01324 9.16332 9.28941 8.72361 9.3533L3.67382 10.0871L7.32788 13.6489C7.64606 13.9591 7.79125 14.4059 7.71614 14.8438L6.85353 19.8732L11.3702 17.4987C11.7635 17.2919 12.2333 17.2919 12.6266 17.4987L17.1433 19.8732L16.2807 14.8438C16.2056 14.4059 16.3508 13.9591 16.6689 13.6489L20.323 10.0871L15.2732 9.3533C14.8335 9.28941 14.4534 9.01324 14.2568 8.6148L11.9984 4.03891Z' fill='%23314578'/%3E%3C/svg%3E");
      }

      .cart-card__delete {
        path {
          fill: $darkGray;
        }
      }
    }
  }
  &:not(:first-child){
    border-top: 1px solid #F2F2F2;
  }
  &__favourite{
    margin-right: 8px;
    flex-shrink: 0;
  }
  &__info{
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  &__right{
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &__img{
    margin-right: 8px;
    overflow: hidden;
    flex-shrink: 0;
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    img{
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      position: absolute;
      max-width: 100%;
      height: 100%;
      width: auto;
    }
  }
  &__content{
    max-width: 320px;
  }
  &__title{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp:3;
    -webkit-box-orient: vertical;
  }
  &__art{
    font-size: 12px;
    line-height: 150%;
    color: #676F7B;
  }
  &__price{
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #676F7B;
    width: 109px;
    text-align: center;
    flex-shrink: 0;
    &--one{
      margin-left: auto;
    }
    span{
      color: $black;
      font-size: 16px;
    }
  }
  &__add{
    width: 202px;
    height: 60px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
    margin-left: 20px;
    svg{
      margin-right: 10px;
    }
  }
}

.address-card{
  padding: 32px 24px 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
  border-radius: 8px;
  &__type{
    color: $darkGray;
  }
  &__street{
    font-size: 20px;
    line-height: 150%;
    margin-top: 10px;
  }
  &__time{
    margin-top: 10px;
    &-wrap{
      display: inline-flex;
      padding: 4px 8px;
      background: #F2F2F2;
      border-radius: 12px;
      font-size: 14px;
      line-height: 16px;
      color: $primary;
    }
  }
  &__btn{
    margin-top: 10px;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
  }
}


@media screen and (max-width: 992px){
  .equip-card{
    padding: 8px 8px 8px 16px;
    background-color: transparent;
    box-shadow: none;
    &--small{
      .equip-card__title{
        &:after{
          display: none;
        }
      }
    }
    &:not(:last-child){
      margin-bottom: 8px;
    }
    &._open{
      .equip-card__title{
        &:after{
          transform: rotate(180deg);
        }
      }
    }
    &__content{
      max-width: unset;
    }
    &__title{
      font-size: 14px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &::after{
        content: '';
        display: block;
        background-image: url(../img/arrow-blue.svg);
        background-repeat: no-repeat;
        flex-shrink: 0;
        margin-left: 10px;
        width: 8px;
        height: 5px;
        background-size: 100% auto;
        background-position: center;
        transition: all .3s;
      }
      br{
        display: none;
      }
    }
    &__num,
    &__img{
      display: none;
    }
    &__desc{
      display: none;
      padding: 8px 16px;
      margin: 0;
      li{
        a{
          color: $primary;
        }
        &:before{
          background-color: $primary;
        }
      }
    }
  }

  .catalog-card{
    box-shadow: none;
    padding: 8px 16px;
    min-height: unset;
    &:hover{
      box-shadow: none;
    }
    &__img,
    &__num{
      display: none;
    }
    &__text{
      max-width: 100%;
    }
    &__title{
      font-size: 14px;
    }
  }

  .cart-card{
    &__add{
      width: 40px;
      height: 40px;
      svg{
        margin: 0;
      }
      span{
        display: none;
      }
    }
  }
}


@media screen and (max-width: 768px){
  .service-card{
    &__btn{
      margin-top: 6px;
    }
  }

  .cart-card{
    display: block;
    padding: 32px 0 16px;
    &__right{
      justify-content: flex-end;
      margin-top: 24px;
    }
    &__calc{
      margin-right: 40px;
    }
    &__delete{
      position: absolute;
      right: 0;
      top: 16px;
    }
    &__favourite{
      position: absolute;
      left: 0;
      bottom: 23px;
    }
    &__price{
      text-align: right;
      width: unset;
    }
  }
}


@media screen and (max-width: 360px) {
  .cart-card{
    &__img{
      width: 70px;
      height: 70px;
    }
    &__title,
    &__price{
      font-size: 13px;
    }
    &__art{
      font-size: 11px;
    }
  }
}
