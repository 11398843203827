@import "../vars.scss";
.breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 32px;
    &__item {
        font-size: 14px;
        line-height: 16px;
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 8px 8px 0;
        &:after {
            content: '';
            z-index: 1;
            width: 16px;
            height: 16px;
            background-image: url(../img/bread-arrow.svg);
            background-size: 100% auto;
            background-position: center;
            background-repeat: no-repeat;
            top: 0;
            right: 0;
            margin-left: 4px;
        }
    }
    span.breadcrumbs__item{
        pointer-events: none;
    }
}


@media screen and (max-width: 992px){
    .breadcrumbs{
        margin-bottom: 8px;
    }
}
