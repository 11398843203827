.search{
  padding-bottom: 95px;
  &__title{
    span{
      color: $primaryHover;
    }
  }
  &__subtitle{
    margin-top: 55px;
  }
  &__grid{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(4,calc(25% - 18px));
    gap: 24px;
  }
}


@media screen and (max-width: 1100px){
  .search{
    &__grid{
      grid-template-columns: repeat(3, calc(33.33% - 16px));
    }
  }
}


@media screen and (max-width: 992px){
  .search{
    &__subtitle{
      margin-top: 24px;
    }
    &__grid{
      grid-template-columns: repeat(2, calc(50% - 12px));
      gap: 16px;
    }
  }
}

@media screen and (max-width: 650px){
  .search{
    &__grid{
      grid-template-columns: 100%;
    }
  }
}
