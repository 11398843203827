.cart{
  padding-bottom: 50px;
  &__wrap{
    display: grid;
    grid-template-columns: calc(100% - 290px) 270px;
    gap: 20px;
    margin-top: 27px;
  }
}

@media screen and (max-width: 1100px){
  .cart{
    &__wrap{
      grid-template-columns: 100%;
      @media screen and (min-width: 992px){
        .buy-card{
          max-width: 270px;
          margin-top: 24px;
        }
      }
    }
  }
}
