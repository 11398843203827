@import '../vars';
.modal {
    background: rgba(49, 69, 120, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 40;
    opacity: 0;
    pointer-events: none;
    &--active{
        opacity: 1;
        pointer-events: unset;
    }
    &__dialog{
        background-color: #fff;
        width: 416px;
        max-width: 100%;
        max-height: 100vh;
        position: relative;
        background-size: auto 50px;
        background-repeat: repeat-x;
        background-position: left bottom;
    }
    &__top{
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 24px;
    }
    &__scroll{
        padding: 0 48px 60px;
        overflow-y: auto;
        height: calc(100% - 72px);
    }
    &__desc{
        margin-top: 24px;
    }
    &__socials{
        margin-top: 8px;
        display: flex;
        align-items: center;
        &-item{
            width: 48px;
            height: 48px;
            padding: 0;
            min-height: unset;
            margin-right: 16px;
        }
    }
    &__form{
        margin-top: 24px;
    }
    &__legal{
        margin-bottom: 24px;
    }
    .form-group{
        margin-bottom: 8px;
        label{
            margin-bottom: 4px;
        }
        input{
            height: 32px;
        }
    }
    &__shops{
        display: flex;
        align-items: center;
        margin: 24px 0 20px;
        &-switch{
            margin-right: 24px;
        }
    }
    &__check{
        margin-top: 24px;
    }
    &__btn{
        margin-top: 24px;
        width: 100%;
        height: 48px;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.01em;
        border-radius: 4px;
    }
    &__addresses{
        margin-top: 24px;
        display: grid;
        grid-template-columns: 100%;
        gap: 24px;
    }
    .custom-select{
        z-index: 50;
        //display: none;
    }
}

.cart-modal{
    display: block;
    .modal__dialog{
        margin-left: auto;
        height: 100vh;
    }
}

.cart-info{
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 5px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
    border-radius: 8px;
    padding: 8px;
    width: 270px;
    z-index: 30;
    &--active{
        opacity: 1;
        pointer-events: unset;
    }
    &__wrap{
        display: grid;
        grid-template-columns: 80px calc(100% - 88px);
        gap: 8px;
        margin-top: 8px;
    }
    &__img{
        img{
            width: 100%;
            display: block;
        }
    }
    &__name{
        font-size: 12px;
        line-height: 150%;
    }
    &__count{
        margin: 8px 0;
        display: flex;
        align-items: center;
    }
    &__num{
        margin-left: 16px;
    }
    &__link{
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.01em;
    }
}

.service-modal{
    .modal__desc{
        margin-top: 12px;
    }
}


@media screen and (max-width: 768px){
    .modal{
        &__scroll{
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .cart-modal{
        display: block;

    }
}


@media screen and (max-width: 576px){
    .cart-modal{
        .modal__dialog{
            height: calc(100vh - 32px);
            top: 32px;
            width: 100%;
        }
    }
}
