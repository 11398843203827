.header-search{
  position: relative;
  //z-index: 31;
  &__bg{
    background: $darkGray;
    opacity: 0.9;
    box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 20;
    display: none;
  }
  &__dropdown{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 50px 0;
    opacity: 0;
    pointer-events: none;
  }
  &__content{
    padding: 10px 24px 0;
    max-height: 365px;
    overflow-y: auto;
  }
  &__group{
    &:not(:last-child){
      margin-bottom: 22px;
    }
  }
  &__title{
    font-weight: 500;
  }
  &__link{
    margin-top: 20px;
    line-height: 17px;
  }
  &__price{
    display: inline-block;
    color: $black;
    &-num{
      color: $primary;
      font-size: 20px;
      line-height: 27px;
    }
  }
  &__all{
    position: absolute;
    top: 0;
    right: 24px;
    height: 48px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: $primary;
    z-index: 33;
  }
  &__num{
    display: inline-flex;
    padding: 4px 8px;
    background-color: $primary;
    border-radius: 12px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    margin-left: 8px;
  }
}

.header-search{
  &__dropdown{

  }
}


@media screen and (max-width: 992px) {
  .header-search{
    &__dropdown{
      width: calc(100% + 79px);
    }
    &__all{
      position: static;
      padding: 0 24px;
    }
  }
}
