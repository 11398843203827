@import 'vars';
html {
    width: 100%;
}

.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

body {
    font-family: $font1;
    font-weight: 400;
    color: $black;
    &.fixed-body {
        overflow-y: hidden;
    }
}

.container {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    @media screen and (max-width: 992px) {
        padding: 0 16px;
    }
}

a {
    &:hover {
        color: $primary;
    }
}
