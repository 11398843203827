.main,
.products,
.collection{
  .swiper{
    &-button{
      &-prev{
        left: unset;
        right: 100%;
        margin-right: 24px;
      }
      &-next{
        left: 100%;
        right: unset;
        margin-left: 24px;
      }
    }
  }
}

.main{
  &__inner{
    position: relative;
  }
  &__slider{
    overflow: hidden;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
    border-radius: 8px;
  }
  .swiper{
    &-wrapper{
      align-items: stretch;
    }
    &-slide{
      height: auto;
    }
    &-pagination{
      position: absolute;
      right: 24px;
      bottom: 24px;
      display: flex;
      justify-content: flex-end;
      width: unset;
      left: unset;
      &-bullet{
        width: 6px;
        height: 6px;
        margin-left: 24px !important;
        margin-right: 0;
        background: #F2F2F2;
        border-radius: 5px;
        opacity: 1;
        position: relative;
        overflow: hidden;
        &-active{
          width: 36px;
          span{
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 0%;
            background-color: $primary;
            display: block;
            transition: all .2s;
            animation: 7s linear 0s bulletAnimate;
            animation-iteration-count: initial;
          }
        }
      }
    }
  }
}

@keyframes bulletAnimate {
  0%   { width: 0; }
  100% { width: 100%; }
}

.banner{
  overflow: hidden;
  display: flex;
  &__img{
    width: 43%;
    position: relative;
    min-height: 240px;
    flex-shrink: 0;
    img{
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      display: block;
    }
  }
  &__content{
    padding: 15px 15px 45px;
    flex-grow: 1;
  }
  &__title{
    max-width: 405px;
    font-weight: 300;
    line-height: 130%;
    strong{
      font-weight: 800;
    }
    span{
      font-weight: 500;
    }
  }
  &__desc{
    margin-top: 8px;
  }
  &__link{
    width: 270px;
    height: 48px;
    margin-top: 18px;
    font-weight: 600;
  }
}

.products{
  padding: 40px 0;
  overflow: hidden;
  &__tabs{
    display: flex;
    align-items: center;
  }
  &__slider{
    margin-top: 24px;
    position: relative;
    .swiper{
      &-wrapper{
        align-items: stretch;
      }
      &-slide{
        height: auto;
        width: 270px;
        @media screen and (min-width: 1220px){
          opacity: 0;
          &-active,
          &-next,
          &-next+.swiper-slide,
          &-next+.swiper-slide+.swiper-slide{
            opacity: 1;
          }
        }
      }
    }
  }
  &__all{
    width: 270px;
    height: 48px;
    margin: 24px auto 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.01em;
  }
}

.services{
  padding: 40px 0 ;
  &__title{
    font-weight: 500;
  }
  &__items{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 16px));
    gap: 24px;
  }
}

.providers{
  margin: 40px 0;
  padding: 64px 0 88px;
  background: #F9F9F9;
  overflow: hidden;
  &__title{
    font-weight: 500;
  }
  &__slider{
    margin-top: 24px;
  }
  .swiper{
    &-button{
      &-prev,
      &-next{
        position: static;
        margin: 0;
        transform: unset;
      }
      &-next{
        margin-left: 24px;
      }
    }
  }
  &__item{
    width: 270px;
    height: 135px;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
    border-radius: 8px;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__nav{
    display: flex;
    align-items: center;
    margin-top: 24px;
  }
}

.about{
  padding: 40px 0;
  &__title{
    font-weight: 500;
  }
  &__wrap{
    margin-top: 24px;
    display: grid;
    grid-template-columns: auto 368px;
    gap: 24px;
  }
  &__items{
    display: grid;
    grid-template-columns: 100%;
    gap: 24px;
  }
  &__desc{
    font-size: 20px;
    line-height: 150%;
    margin-bottom: 24px;
    a{
      color: $primary;
      &:hover{
        color: $primaryHover;
      }
    }
  }
  &__text{
    ul{
      li{
        &:not(:last-child){
          margin-bottom: 0;
        }
      }
    }
  }
  &__card{
    box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
    border-radius: 8px;
    background-color: $primary;
    color: #fff;
    position: relative;
    padding: 32px 24px 40px;
  }
  &__img{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    pointer-events: none;
  }
  &__subtitle,
  &__address{
    position: relative;
    z-index: 2;
    max-width: 248px;
  }
  &__address{
    margin-top: 10px;
  }
  &__desc2{
    margin-top: 10px;
    font-weight: 600;
  }
  &__banner{
    display: block;
    img{
      width: 100%;
      display: block;
      box-shadow: 0px 4px 16px rgba(0, 51, 153, 0.04), 0px 2px 2px rgba(0, 51, 153, 0.08);
    }
  }
}

.info{
  padding: 40px 0;
  &__items{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 16px));
    gap: 24px;
  }
}


@media screen and (max-width: 1280px){
  .main,
  .products,
  .collection{
    .swiper{
      &-button{
        &-prev{
          margin-right: -5px;
        }
        &-next{
          margin-left: -5px;
        }
      }
    }
  }
}


@media screen and (max-width: 1220px){
  .main{
    .swiper{
      &-button{
        &-prev{
          margin-right: 0;
          right: unset;
          left: 5px;
        }
        &-next{
          margin-left: 0;
          left: unset;
          right: 5px;
        }
      }
    }
  }

  .products{
    &__nav{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 24px;
      .swiper{
        &-button{
          &-prev,
          &-next{
            margin: 0;
            position: static;
            transform: unset;
          }
          &-next{
            margin-left: 24px;
          }
        }
      }
    }
    &__all{
      margin-top: 34px;
    }
  }

  .services,
  .info{
    &__items{
      grid-template-columns: repeat(2, calc(50% - 12px));
    }
  }
}


@media screen and (max-width: 992px){
  .main{
    display: none;
  }

  .products{
    padding: 0 0 16px;
    &__slider{
      margin-top: 16px;
    }
  }

  .services{
    padding: 16px 0 64px;
    &__items{
      margin-top: 40px;
    }
  }

  .providers{
    padding: 32px 0 48px;
    margin: 0;
    &__slider{
      margin-top: 16px;
    }
    &__item{
      max-width: 100%;
      width: 328px;
      height: 192px;
    }
    &__nav{
      justify-content: flex-end;
    }
  }

  .about{
    padding: 16px 0;
    &__wrap{
      grid-template-columns: 100%;
    }
    &__items{
      grid-template-columns: repeat(2, calc(50% - 12px));
    }
  }

  .info{
    padding: 32px 0 16px;
    &__items{
      grid-template-columns: repeat(2,calc(50% - 12px));
    }
  }
}

@media screen and (max-width: 768px){
  .services,
  .about,
  .info{
    &__items{
      grid-template-columns: 100%;
      gap: 16px;
    }
  }
}
