.choose{
  &__grid{
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 20px));
    gap: 32px 40px;
    margin-top: 64px;
    max-width: 992px;
  }
  &__group{
    &-title{
      margin-bottom: 16px;
    }
    &-wrap{
      margin-top: -16px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: calc(100% + 22px);
    }
  }
  &__full{
    grid-column: 1/3;
  }
  &__check{
    margin: 16px 22px 0 0;
  }
  &__contacts{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    .form-group{
      input{
        height: 32px;
      }
      label{
        margin-bottom: 4px;
      }
    }
  }
  &__btn{
    width: 259px;
    height: 48px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.01em;
  }
}


@media screen and (max-width: 992px){
  .choose{
    &__grid,
    &__contacts{
      grid-template-columns: 100%;
    }
    &__full{
      grid-column: 1/2;
    }
    &__group{
      &-wrap{
        width: 100%;
      }
    }
  }
}
