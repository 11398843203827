.cart--favourite{
  .cart__wrap{
    grid-template-columns: 100%;
    gap: unset;
  }

  .cart-card{
    &__delete{
      margin-left: 23px;
    }
  }
}
